<template>
  <div>
    <div class="contact-us">
      <!-- <Banner :dataBanner="dataBanner"></Banner> -->
      <div
        class="contact-us__container"
        data-aos="fade-up"
        data-aos-duration="500"
        data-aos-offset="300"
      >
        <div class="contact-us__content">
          <h2>{{ contact.title }}</h2>
        </div>
        <div class="contact-us__block">
          <div class="contact-us__items">
            <a
              v-for="(item, index) in data"
              :key="index"
              class="contact-us__item"
              :href="item.href"
              target="_blank"
            >
              <div>
                <img alt="icon-contact" :src="item.icon" />
              </div>
              <div class="contact-us__item--content">
                <h3>{{ item.title }}</h3>
                <div v-for="(sub, indx) in item.sub" :key="indx">
                  <p>{{ sub }}</p>
                </div>
              </div>
            </a>
          </div>
          <div class="contact-us__content form">
            <a
              href="https://www.google.com/maps/place/Bcons+Tower+II/@10.808011,106.7136769,17z/data=!3m1!4b1!4m5!3m4!1s0x31752940e575ae11:0x296ca38a37758ffa!8m2!3d10.8080115!4d106.7137077?hl=vi"
              target="_blank"
              ><img :src="map" />
            </a>
            <div>
              <p class="title-form mb-5">
                {{ contact.welcome }}
              </p>
              <img alt="imgContact" :src="imgContact" />
            </div>
            <div class="">
              <Contact />
              <!-- <FormMail
                :dataMail="contact.dataFormMail"
                :skills="skills"
              ></FormMail> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import axios from 'axios';

// const FormMail = () => import('../components/FormMail.vue');
const Footer = () => import('../views/FooterNew.vue');
const Contact = () => import('../components/Renew/Home/Contact.vue');

const HRM_URL = process.env.VUE_APP_API_URL;

export default {
  name: 'Contact-us',
  data() {
    return {
      imgContact: '/images/contact/img-form.png',
      contact: this.$t('contact'),
      cards: this.$t('contact').cards,
      dataBanner: {
        title: this.$t('contact').title,
        images: ['/images/contact/bg-banner-contact.png'],
        sub: this.$t('contact').sub
      },
      data: [
        {
          icon: '/images/icons/contact/address.svg',
          href: this.$t('footer').contents[0].ref1
        },
        {
          icon: '/images/icons/contact/clock.svg'
        },
        {
          icon: '/images/icons/contact/phone.svg',
          href: this.$t('footer').contents[1].ref2
        },
        {
          icon: '/images/icons/contact/envelope.svg',
          href: this.$t('footer').contents[1].ref1
        }
      ],
      seo: this.$t('seo').contact,
      skills: []
    };
  },
  metaInfo() {
    return {
      title: this.seo.title,
      meta: [
        {
          name: 'description',
          content: this.seo.description
        },
        {
          property: 'og:title',
          content: this.seo.title
        },
        {
          property: 'og:description',
          content: this.seo.description
        }
      ]
    };
  },
  components: {
    Contact,
    Footer
  },
  computed: {
    ...mapState({
      lang: (state) => state.langs.lang
    })
  },
  mounted() {
    this.data = this.data.map((item, indx) => ({
      ...item,
      title: this.cards[indx].title,
      sub: this.cards[indx].sub
    }));
    this.handleGetSkills();
  },
  methods: {
    async handleGetSkills() {
      const res = await axios.get(`${HRM_URL}/skills`);
      this.skills = res.data.data;
    }
  },
  watch: {
    lang() {
      this.contact = this.$t('contact');
      this.cards = this.$t('contact').cards;
      this.seo = this.$t('seo').contact;
      this.dataBanner = {
        title: this.$t('contact').title,
        images: ['/images/contact/bg-banner-contact.png'],
        sub: this.$t('contact').sub
      };
      this.data = [
        {
          icon: '/images/icons/contact/address.svg',
          href: this.$t('footer').contents[0].ref1
        },
        {
          icon: '/images/icons/contact/clock.svg'
        },
        {
          icon: '/images/icons/contact/phone.svg',
          href: this.$t('footer').contents[1].ref2
        },
        {
          icon: '/images/icons/contact/envelope.svg',
          href: this.$t('footer').contents[1].ref1
        }
      ].map((item, indx) => ({
        ...item,
        title: this.cards[indx].title,
        sub: this.cards[indx].sub
      }));
    }
  }
};
</script>

<style lang="scss" scoped>
.contact-us {
  width: 100%;
  min-height: 900px;
  padding: 50px 0 50px;
  background: url('/images/bg/background.png');
  background-repeat: no-repeat;
  background-size: cover;
  .contact-us__container {
    width: 80%;
    margin: auto;
    padding: 80px 0;
    padding-top: 120px;
  }
  .contact-us__content {
    h2 {
      font-weight: 700;
      font-size: 40px;
      color: var(--blue-text);
      z-index: 0;
      text-transform: none;
      text-align: center;
    }
    span {
      font-weight: 400;
      font-size: 18px;
      color: var(--grey-text);
    }
    &.form {
      display: flex;
      justify-content: space-between;
      gap: 5%;
      .title-form {
        font-weight: 700;
        font-size: 40px;
        color: var(--blue-text);
      }
      img {
        border-radius: 10px;
        width: 100%;
        object-fit: contain;
      }
    }
  }
  .contact-us__block {
    display: flex;
    justify-content: space-between;
    padding-top: 100px;
    gap: 100px;
    flex-direction: column;
  }
  .contact-us__items {
    display: flex;
    gap: 32px;
    width: 100%;
    img {
      height: 40px;
    }
  }
  .contact-us__item {
    flex: 1;
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: #fff;
    text-align: center;
    color: var(--black-text);
    &:hover {
      text-decoration: none;
    }
  }
  .contact-us__item--content {
    h3 {
      font-weight: 700;
      font-size: 18px;
      color: var(--grey-text);
    }
    p {
      font-weight: 400;
      font-size: 18px;
      margin-bottom: 10px;
      line-height: 1.5;
    }
  }
  .contact-us__form {
    position: relative;
    width: 50%;
    min-width: 400px;
    border-radius: 10px;
    background-color: transparent;
    padding-top: 50px;
    &::after {
      content: '';
      position: absolute;
      top: -33%;
      right: -12%;
      width: 300px;
      height: 250px;
      background-image: url('/images/bg/penguin.png');
      background-size: cover;
      display: none;
    }
  }
  @media (max-width: 1024px) {
    & {
      height: unset;
    }
    .contact-us__block {
      flex-direction: column;
      gap: 60px;
    }
    .contact-us__items,
    .contact-us__form {
      flex-direction: column;
      width: 100%;
    }
    .contact-us__content {
      width: 100%;
      flex-direction: column;
      gap: 20px !important;
    }
  }
  @media (max-width: 768px) {
    .contact-us__container {
      padding-top: 100px;
    }

    & {
      padding-top: 0;
    }
    .contact-us__form {
      min-width: 0;
      padding: 10px;
      &::after {
        display: none;
      }
    }
    .contact-us__content {
      margin-top: 50px;
      h2 {
        font-size: 32px;
      }
      &.form {
        .title-form {
          font-size: 32px;
        }
      }
    }
  }
}
</style>
